<mat-dialog-content>
  <div class="md-dialog-content">
    <h2 style="overflow-wrap: break-word;">{{data.name}}</h2>
    <h3 *ngIf="data.use === 'relevant'">Bitte machen Sie eine Angabe, wieso diese Maßnahme für Sie nicht relevant ist.</h3>
    <h3 *ngIf="data.use === 'comment' || data.use === 'check'">Notieren Sie hier Ihre Anmerkungen zur Maßnahme, zum Beispiel wie Sie sie umgesetzt haben.</h3>
    <h3 *ngIf="formattedCreateDate !== '' && formattedUpdateDate == ''">Kommentar erstellt am {{ formattedCreateDate }}</h3>
    <h3 *ngIf="formattedUpdateDate != ''">Kommentar bearbeitet am {{ formattedUpdateDate }}</h3>
    <mat-form-field style="width: 800px; height: 300px">
      <textarea matInput style="width: 800px; height: 300px" [(ngModel)]="inputText"></textarea>
    </mat-form-field>
  </div>
  <button class="button-confirm" mat-button (click)="closeAndSaveDialog()">Speichern</button>
  <button class="button-confirm" mat-button (click)="closeAndReset()" style="margin-left: 5px !important;">Kommentar entfernen</button>
  <button class="button-confirm" mat-button (click)="closeDialog()" style="margin-left: 5px !important;">Abbrechen</button>
</mat-dialog-content>
